import React, { Component } from 'react';

class Services extends Component {
  render() {
    return (
      <div>
        <h1>שרותים</h1>

        <h3>בדיקות ציוד</h3>
        <h4>בדיקות יבוא ותאימות
        בדיקת גופי תאורה וציוד לעמידה בתקנים, מפרטים ודרישות</h4>
        <ul>
          <li>בטיחות פוטוביולוגית - ת"י 62471, ת"י 62778</li>
          <li> ספקטרום אור SPD, מקדם העברת צבע CRI, טמפרטורת צבע CCT </li>
          <li>הבהוב - Flicker IEEE 1789</li>
          <li>דרישות מפרט בינמשרדי 08</li>
        </ul>
        <h4>ייעוץ וכתיבת מפרטים</h4>
        <ul>
          <li>כתיבת מפרטים טכניים</li>
          <li>התאמה של הדרישות לתקנים ולתקנות</li>
          <li>התאמה של הדרישות לאופי המתקן, צרכים טכניים ואדריכליים</li>
          <li>התאמה לדרישות רשויות</li>
          <li>התאמה לתקציב ואילוצי הפרוייקט</li>
        </ul>

        <h4>בדיקות התאמה למפרטים</h4>
        <ul>
          <li>בחינת מסמכי הגשה ותעודות בדיקה</li>
          <li>ייעוץ לוועדת מכרזים וחוות דעת</li>
        </ul>

        <p>בדיקת ציוד ובדיקת מסמכים נלוים מבחינת עמידה של הציוד בדרישות לאורך זמן.
           לרבות תנאי אחריות יצרן בישראל, התאמה למיקום התקנה בתנאי מזג האויר בארץ.
           אורך חיים מוצהר, ועוד
             </p>
        <p></p>
        <p></p>
        <h4> מדידת מתקני תאורת פנים וחוץ</h4>
        <h4> בניה ירוקה - התאמה לדרישות תאורה ת"י 5281</h4>
        <h4> חיסכון באנרגיה בתאורה - התאמה לדרישות תאורה ת"י 5280</h4>
        <h4>זיהום אור</h4>
        <ul>
          <li>אור פולש ועמידה בדרישות שמירה על הטבע , מניעת זיהום אורי ודרישות רט"ג</li>
          <li>מניעת אור פולשני מתאורת חוץ - CIE150</li>
        </ul>

        <h4>כבישים ומנהרות</h4>
        <ul>
          <li>איכות הארה לפי ת"' 13201 ותקנים רלונטיים למתקן</li>
        </ul>

        <h4>מתקני ספורט</h4>
        <h4>תאורת חירום ומילוט</h4>
        <h4>תאורת פנים</h4>
        <h3>בטיחות
           קרינה אולטרה סגולה UV  </h3>
        <p>סקר מפעלי - מקורות UV וציוד הגנה</p>

        <h3>הדרכה</h3>
        <ul>
          <li>ימי עיון וסדנאות מקצועיות</li>
          <li>הדרכות יזומות - מקומות עבודה</li>
        </ul>

      </div>
    );
  }
}

export default Services;
