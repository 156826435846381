import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

// Components
import Layout from './Layout'
import HeFooter from './HeFooter'

// Pages
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Home from './Home'
import NoMatchHe from './NoMatchHe'

const baseUrl = '' // '/ibn'

import '../../css/clean-blog.css'
import '../../css/ibn.css'
//import './ibn_he.css' // default is he
//import './ibn_he.css'
import top_img from '../../img/about-bg_03.jpg'
import NavLink from 'react-bootstrap/NavLink';
import ErrorBoundary from '../../components/ErrorBoundry';

const homeUrl = baseUrl + '/';

interface PageDef {
    url: string;
    title: string;
    hidden?: boolean;
    component: React.ComponentType ;
}

interface HeNavbarProps {
    bigImage: boolean;
    pages: PageDef[];
}



const anonymous_pages: PageDef[] = [
    { url: '/', title: 'עמוד הבית', component: Home },
    { url: '/contact', title: 'יצירת קשר', component: Contact },
    { url: '/services', title: 'שרותים', component: Services },
    { url: '/about', title: 'עלינו', component: About },
]
const authenticated_pages: PageDef[] = [
    { url: '/', title: 'עמוד הבית', component: Home },
    { url: '/contact', title: 'יצירת קשר', component: Contact },
    { url: '/services', title: 'שרותים', component: Services },
    { url: '/about', title: 'עלינו', component: About },
    { url: '/lab', title: 'תפעול', component: About },
]



const HeNavbar = (props: HeNavbarProps) => {
    return (
        <Navbar bg="light" expand="lg" className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <Navbar.Brand href={homeUrl}  >
                <span className={props.bigImage ? "bigImage" : "smallImage"}>איי בי אן מעבדות</span></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {/* <Nav.Item>
                    <Link to={baseUrl + '/'} > עמוד הבית </Link>
                    </Nav.Item> */}

                    {props.pages.map((p) => !p.hidden ? <Nav.Link key={p.url} href={baseUrl + p.url} >{p.title}</Nav.Link> : null)}

                    {/* <li><a href={'/admin'} className="nav-link">Admin</a></li> */}
                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown> */}
                </Nav>
                {/* <Form inline>
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-success">Search</Button>
            </Form> */}
            </Navbar.Collapse>
        </Navbar>
        // //     <Breadcrumb>
        // //         <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
        // //         <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
        // //             Library
        // // </Breadcrumb.Item>
        // //         <Breadcrumb.Item active>Data</Breadcrumb.Item>
        // //     </Breadcrumb>
        //     <div>
        //         {/* <nav className="navbar navbar-expand-sm navbar-light bg-light">
        //             <ul className="navbar-nav ml-auto">
        //                 <li><Link to={baseUrl + '/'} className="nav-link"> עמוד הבית </Link></li>
        //                 <li><Link to={baseUrl + '/contact'} className="nav-link">יצירת קשר</Link></li>
        //                 <li><Link to={baseUrl + '/about'} className="nav-link">עלינו</Link></li>
        //             </ul>
        //         </nav> */}
        //         <hr />
        //         <header className="page-header header container-fluid">
        //         </header>
        //     </div> 


    )
}

interface PageHeaderProps {
    bigImage: boolean;
    pages: PageDef[];
}

const PageHeader = (props: PageHeaderProps) => (
    < header className="masthead" style={{
        backgroundImage: "url(" + top_img + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }} >
        {/* Navigation */}
        < div className="xxoverlay" >
            <HeNavbar {...props} />
        </div >
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 mx-auto">
                    <div className={props.bigImage ? "page-heading" : "page-heading-small"}>
                        <h1>איי בי אן מעבדות</h1>
                        <span className="subheading">איכות נמדדת</span>
                    </div>
                </div>
            </div>
        </div>
    </header >
)

class HeRoot extends Component<any, any> {
    constructor(props: any) {
        super(props);
        //        this.state = { user: null, userToken: null };
    }

    render() {
        const pages = this.props.isAuthenticated ? authenticated_pages : anonymous_pages;
        const bigImage = window.location.pathname == homeUrl;
        return (
            <Router>
                <PageHeader bigImage={bigImage} pages={pages} />

                <hr />
                <ErrorBoundary>
                    <Routes>
                        {pages.map((p) => <Route path={baseUrl + p.url} Component={p.component} key={p.url} />)}
                        <Route Component={NoMatchHe} />
                    </Routes>
                </ErrorBoundary>

                {/* <!-- Footer --> */}
                <HeFooter />
            </Router>
        );
    }
}


export default HeRoot;
