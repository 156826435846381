import React, { Component } from 'react';

import HeRoot from "./pages/he/HeRoot";

const baseUrl = '/ibn'

import './css/ibn.css'
import logo from './img/logo1.svg';

class App extends Component {
    render() {
        return (
            <div className="container">
                {/* <h2>App.tsx</h2> */}
                <HeRoot />
            </div>
        );
    }
}


export default App;
