import React, { Component } from 'react';

class Home extends Component {
  render() {
    return (
      <div>
        <h2>עמוד הבית</h2>
        <p>איי בי אן מספקת שירותי מדידה ובדיקת איכות ליבואנים ולצרכני תאורה</p>
        <div className="container">
          <div className="row">
            <div className="col-sm">
              מעבדת מדידות מוסמכת ISO17025
              בדיקות ציוד ומדידות מתקנים באתר הלקוח
    </div>
            <div className="col-sm">
              ניסיון עשיר בליווי תהליכי רכש: אפיון, התאמה לתקנים ודרישות, בדיקת מכרזים, בדיקות ביצוע
    </div>
            <div className="col-sm">
              שירותים משלימים: הדרכה, פיתוח מדידות ושיטות מדידה, ייעוץ בענייני תקינה ומדיניות
    </div>
          </div>
        </div>        </div>
    );
  }
}

export default Home;
