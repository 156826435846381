import React, { Component } from 'react';

class NoMatchHe extends Component {
  render() {
    return (
        <div>
          <h2>דף לא נמצא</h2>
        </div>
    );
  }
}

export default NoMatchHe;
