import React, { Component, createRef } from 'react';




class About extends Component {
  render() {
    return (
      <div>
        <h2>עלינו</h2>
        <p>איי בי אן מעבדות נוסדה ב 2010 במטרה לענות על הצורך במתן שירותים בקרה ופיקוח איכות לתאורה.
        </p>

        <p>.למעבדה הסמכה ISO17025 בפיקוח הרשות הלאומית להסמכת מעבדות</p>

        איי בי אן היא מעבדה מוסמכת ISO17025 לביצוע מדידות תאורה וציוד 
שירותי המעבדה
מדידות תאורה באתרים מגוונים
כבישים - מדידות הארה ובהיקות, בחינת עמידה בת"י 13201, ת"י 1862  ודרישות תכנון
תאורת פנים - ת"י 12464 חלק 1
תאורת חוץ- ת"י 12464 חלק 2
תאורת חירום והכוונה- תקנות הבניה ות"י 1838
תאורת מתקני ספורט - דרישות FIFA ,UEFA , FIBA ודרישות גופים רלוונטיים בישראל
מנהרות - תאורת חירום והכוונה
זיהום אורי ואור פולש - תקנים CIE150 , דרישות רט"ג
ספקטרום וניתוח - CCT, יחס רכיבים, תכולת קרינה קצרת גל Blue Hazard  ועוד
הבהוב חשמלי ואופטי

בדיקת ציוד וסביבת עבודה
בטיחות פוטוביולוגית - Blue Hazard , ת"י 62471 (כולל דרישות ת"י 20 62778)
ספקטרום של גופי תאורה
הבהוב IEEE1789
שילוט וסימון - ת"י 20 - 22
סיכוני UV, ציוד ומיגון במקומות עבודה לפי הנחיות ICNIRP OSHA IEC62471 ונהלים מקומיים

ליווי פרויקטים ותהליכי רכש
הגדרת דרישות בהתאם לתקנים ולפי צרכי הלקוח
ייעוץ טכני בקביעת מדיניות כללית 
כתיבת מפרטים טכניים
ניתוח טכנו-כלכלי לחיסכון באנרגיה
בחינת התאמה של ציוד למפרט ובכלל זה בדיקה מקצועית של מסמכים ותיעוד הציוד
בדיקת ביצוע - התאמה לדרישות ותכנון

הדרכה
ימי עיון וקורסים בנושאי תאורה
הדרכה כחלק ממדיניות מפעלית להגברת מודעות לסיכונים


בנוסף, לאיי בי אן יכולות לפיתוח שיטות מדידה יעודיות בנושאים שונים ובטכנולוגיות עדכניות - בהתאם לצרכים ולתקנים הרלונטיים.


ניהול

המעבדה בניהולה של ד"ר אינה ניסנבאום, מהנדסת חשמל ואלקטרוניקה אשר התמחתה בלימודי הנדסה (תואר ראשון, שני ושלישי) ולימודי אנרגיה באוניברסיטת תל אביב. 

ד"ר ניסנבאום בעלת ניסיון עשיר בתאורה ובכלל זה ייעוץ ותכנון במאות פרויקטים, וייעוץ במוסדות שונים: משרד הביטחון, משרד הבריאות, משרד העבודה, משרד התשתיות הלאומיות, משרד הכלכלה, שרותי בריאות כללית, רשות שדות התעופה, עיריות, רשויות ומועצות רבות, בנקים, רשת סופרפארם ועוד.

ד"ר ניסנבאום משתתפת בפעילות תקינה, בהכנתם של תקנות ותקני תאורה שונים,  מרצה לתאורה באוניברסיטת תל אביב, המוסד לבטיחות ולגיהות ועוד. כמו כן, היא רשומה ברשימת החברות המומלצות ע"י משרד התשתיות לביצוע סקרי אנרגיה.

ד"ר אינה ניסנבאום יושבת ראש של האגודה הישראלית לתאורה, חברת בהנהלת ענף חשמל ואלקטרוניקה בלשכת המהנדסים והאדריכלים וחברה דירקטוריון מכון התקנים הישראלי.



        <p>המעבדה מציעה שירותי בדיקה וחוות דעת מוסמכת </p>

      </div>
    );
  }
}

export default About;